.container {
  position: relative;
  margin-left: 2.75rem;
  margin-right: 3rem;
}

.container[aria-busy='true'] {
  filter: blur(5px);
  pointer-events: none;
}

.heading {
  margin: 0 0 2.5rem -2.75rem;
  padding-left: 2.75rem;
  background: no-repeat left top 40%/2.375rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1rem;
  font-weight: normal;
}

.ctaLine {
  text-align: center;
}

.ctaLink {
  composes: buttonPrimary from '../Button/Button.module.css';
}

.ctaButton {
  composes: buttonPrimary from '../Button/Button.module.css';
  display: block;
  margin: 3rem auto 2.5rem;
}
