.heading {
  composes: heading from '../../components/CredentialFlow/CredentialFlow.module.css';
  background-image: url('../Attester/twitter.svg');
}

.twitterInputContainer {
  composes: input from '../../components/Input/Input.module.css';
  display: flex;
  align-items: center;
  width: auto;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.twitterInputContainer::before {
  content: '@';
}

.twitterInput {
  width: 100%;
  background: transparent;
  color: inherit;
  font-size: inherit;
  border: none;
  padding: 0.25rem 0.25rem 0.25rem 0;
}

.inputError {
  composes: error from '../../components/Input/Input.module.css';
}

.inputError:not([hidden]) {
  width: calc(100% - 6rem);
}

.tweetContainer {
  composes: input from '../../components/Input/Input.module.css';
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 5.5rem;
  padding: 0.5rem;
  margin-bottom: 2.5rem;
}

.tweetInput {
  align-self: stretch;
  flex: 1 1 auto;
  height: 100%;
  min-height: 4.5rem;
  background: transparent;
  color: inherit;
  font-size: inherit;
  border: none;
  padding: 0.25rem 0.25rem 0.25rem 0;
}

.profile {
  display: grid;
  grid-template-areas: 'key value' 'key value';
  width: calc(250rem / 16);
  gap: 0.5rem;
  margin-bottom: 2rem;
}
