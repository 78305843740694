.disconnectContainer {
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 1rem;
  gap: 0.75rem;
}

.disconnect {
  composes: buttonPrimary from '../Button/Button.module.css';
  height: 1.5rem;
  font-size: 0.75rem;
  padding: 0 0.75rem;
  border-radius: 0.375rem;
}
