.iframe {
  overflow: hidden;
  border: medium none;
  height: 2rem;
}

.iframeLoading {
  composes: iframe;
  visibility: hidden;
}
