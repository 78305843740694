.container {
  box-sizing: border-box;
  width: 100%;
  background-color: rgb(var(--color-magenta-secondary-rgb) / 50%);
  color: var(--color-light);
  position: relative;
  border-radius: 0.5rem;
  padding: 0.5rem 3rem 0.5rem 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.heading {
  margin: 0;
  font-size: 1rem;
}

.message {
  composes: heading;
  font-style: italic;
}

.icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 0.75rem;
  right: 0.875rem;
  background: no-repeat center/100%;
}

.spinner {
  composes: spinner from '../Spinner/Spinner.module.css';
  composes: icon;
  right: 1rem;
  left: auto;
  transform: none;
}

.checkmark {
  composes: icon;
  background-image: url('./checkmark.svg');
}

.exclamation {
  composes: icon;
  background-image: url('./exclamation.svg');
}
