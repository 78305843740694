.link {
  display: block;
  margin-block: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-left: 1rem;
  background: url('chevron-left.svg') no-repeat left center/1rem;
  color: inherit;
}
