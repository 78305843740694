.heading {
  composes: heading from '../CredentialFlow/CredentialFlow.module.css';
}

.buttonsLine {
  text-align: center;
}

.ctaButton {
  composes: ctaButton from '../CredentialFlow/CredentialFlow.module.css';
  display: inline-flex;
  margin: 0;
}

.profile {
  display: grid;
  grid-template-areas: 'key value' 'key value';
  width: calc(250rem / 16);
  gap: 0.5rem;
  margin-bottom: 2rem;
}
