.explainerContainer {
  position: absolute;
  top: 0.5rem;
  right: 0;
  margin: 0 0 0 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2;
}

.showExplainer {
  composes: button from '../../components/Button/Button.module.css';
  width: 1rem;
  height: 1rem;
  background: url('info.svg') no-repeat center/100%;
  z-index: 1;
}

.hideExplainer {
  composes: showExplainer;
  background-image: url('close.svg');
}

.explainer {
  position: relative;
  top: -1.5rem;
  border: 1px solid var(--color-light);
  border-radius: 0.625rem;
  background-color: var(--color-magenta);
  color: var(--color-light);
  padding: 0.75rem;
  line-height: 1.125rem;
}
