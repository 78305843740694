.heading {
  font-weight: normal;
  font-size: 1rem;
  margin: 0;
}

.credentials {
  list-style: none;
  padding: 0;
  margin-top: 1.375rem;
}

.credential {
  --credential-logo: '';

  display: block;
  margin-block: 1rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding-left: 3.25rem;
  background:
    var(--credential-logo) no-repeat left top 40%/2.375rem,
    url('chevron-right.svg') no-repeat left 2.5rem top 45%/0.875rem;
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
}

.twitter {
  composes: credential;

  --credential-logo: url('/src/frontend/views/Attester/twitter.svg');
}

.email {
  composes: credential;

  --credential-logo: url('/src/frontend/views/Attester/email.svg');
}

.discord {
  composes: credential;

  --credential-logo: url('/src/frontend/views/Attester/discord.svg');
}

.github {
  composes: credential;

  --credential-logo: url('/src/frontend/views/Attester/github.svg');
}

.twitch {
  composes: credential;

  --credential-logo: url('/src/frontend/views/Attester/twitch.svg');
}

.telegram {
  composes: credential;

  --credential-logo: url('/src/frontend/views/Attester/telegram.svg');
}

.youtube {
  composes: credential;

  --credential-logo: url('/src/frontend/views/Attester/youtube_beta.svg');
}

.buttonPrimary {
  composes: buttonPrimary from '../../components/Button/Button.module.css';
  margin: 2.5rem auto;
  display: block;
}

.buttonLink {
  composes: buttonPrimary from '../../components/Button/Button.module.css';
  margin: 2.5rem auto;
}

.install {
  margin-top: 2rem;
  padding-top: 4rem;
  background:
    url('../../images/exclamation.svg') no-repeat top,
    url('../../components/Branding/sporran-logo.svg') no-repeat bottom;
  padding-bottom: 7rem;
  text-align: center;
}

.unsupported {
  composes: install;
  padding-bottom: 0;
  background-size: auto, 0;
}

.textLink {
  color: var(--color-magenta);
  text-decoration: none;
}

.textLink:hover {
  text-decoration: underline;
}

.webstore {
  display: block;
  height: 3rem;
  margin-bottom: 1rem;
}

.chrome {
  composes: webstore;
  background: url('chrome.svg') no-repeat center;
}

.firefox {
  composes: webstore;
  background: url('firefox.svg') no-repeat center;
}

.connectContainer {
  margin-top: 3rem;
  position: relative;
}

.connect {
  height: 100%;
}

.connect[aria-busy='true'] {
  filter: blur(5px);
  pointer-events: none;
}

.extension {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.extensionInput {
  composes: input from '../../components/Input/Input.module.css';
  border-radius: 0.25rem;
  max-width: calc(300rem / 16);
  margin: 0;
  box-shadow: none;
  border: 0.75px solid rgb(var(--color-white-rgb) / 50%);
}

.authorize {
  margin: 3rem 0 1.75rem;
  text-align: center;
}
