.heading {
  composes: heading from '../../components/CredentialFlow/CredentialFlow.module.css';
  background-image: url('../Attester/youtube.svg');
}

.buttonsLine {
  text-align: center;
}

.youtubeButton {
  composes: ctaButton from '../../components/CredentialFlow/CredentialFlow.module.css';
  display: inline-flex;
  margin: 0;
}

.youtubeButton::after {
  content: '';
  width: 5rem;
  height: 2rem;
  margin-left: 0.5rem;
  background: url('./youtube_brand.svg') no-repeat center/100%;
}

.channel {
  display: grid;
  grid-template-areas: 'key value' 'key value';
  width: calc(250rem / 16);
  gap: 0.5rem;
  margin-bottom: 2rem;
}
