.button {
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.buttonPrimary {
  composes: button;
  height: 2rem;
  padding: 0 3rem;
  align-self: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-magenta);
  box-shadow:
    2px 2px 3px 1px rgb(var(--color-black-rgb) / 15%),
    -2px -2px 3px 1px rgb(var(--color-white-rgb) / 30%);
  text-decoration: none;
}

.buttonPrimary:active {
  transform: translateY(2px);
}

.buttonPrimary:disabled {
  background-color: var(--color-grey);
  color: rgb(var(--color-white-rgb) / 50%);
}
