export const paths = {
  home: '/',
  telegram: '/telegram',
  twitter: '/twitter',

  email: '/email',
  discord: '/discord',
  github: '/github',
  twitch: '/twitch',
  youtube: '/youtube',
};
