.input {
  font-size: 0.875rem;
  display: block;
  width: 85%;
  height: 2.125rem;
  border: 1px solid var(--color-light);
  border-radius: 0.625rem;
  background: rgb(193 84 118 / 50%);
  color: var(--color-white);
  box-shadow:
    2px 2px 4px 0 rgb(var(--color-black-rgb) / 30%),
    -2px -2px 4px 0 rgb(var(--color-white-rgb) / 30%);
  margin-bottom: 1.25rem;
  margin-top: 0.25rem;
  padding: 0 0.5rem;
}

.input:invalid,
.input[aria-invalid],
.input[data-invalid] {
  background: #752143;
}

.error:not([hidden]) {
  display: inline-block;
  width: calc(85% - 4rem);
  margin-inline: 1.5rem;
  padding: 0.375rem 0.5rem 0.25rem 2rem;
  color: var(--color-magenta);
  background: var(--color-white) url('./exclamation.svg') no-repeat left
    0.375rem center/1.25rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
