@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.spinner {
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url('spinner.svg') no-repeat center/100%;
  animation: rotation 1.5s infinite linear;
}
