.copy {
  border: 0;
  padding: 1.75rem 0 0;
  display: inline-flex;
  cursor: pointer;
  background: url('copy.svg') no-repeat center top;
  width: 2rem;
  height: 3rem;
  right: 0.25rem;
  font-size: 0.875rem;
  color: var(--color-white);
  position: relative;
}

.copied {
  composes: copy;
}

.copied::after {
  content: 'copied to clipboard';
  width: 9em;
  position: absolute;
  top: 2.75em;
  left: -3.5em;
  display: inline-block;
  padding: 0.5em 0.75em;
  background: var(--color-magenta);
  border: 1px solid var(--color-light);
  border-radius: 0.75em;
  box-shadow: -2px -2px 4px 0 rgb(var(--color-white-rgb) / 30%);
}
