.heading {
  composes: heading from '../../components/CredentialFlow/CredentialFlow.module.css';
  background-image: url('../Attester/email.svg');
}

.formInput {
  composes: input from '../../components/Input/Input.module.css';
  margin-bottom: 0;
}

.inputError {
  composes: error from '../../components/Input/Input.module.css';
}

.profile {
  display: grid;
  grid-template-areas: 'key value' 'key value';
  width: calc(250rem / 16);
  gap: 0.5rem;
  margin-bottom: 2rem;
}
